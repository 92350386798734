// src/components/GameSession/ActionButtons.js

import React, {useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';

import CELLS from '../../utils/cells';
import UNITS from '../../utils/units';
import {BASE_URL} from '../../actions/gameSessionActions';

import './ActionButtons.css';

const ActionButtons = ({
                           selectedCell,
                           userNameMap,
                           user,
                           game,
                           onEndTurn,
                       }) => {
    const {maps} = useSelector((state) => state.gameSession);

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isUnitMenuOpen, setIsUnitMenuOpen] = useState(false);

    const handleClickWithDisable = (action) => {
        if (buttonsDisabled) return;
        setButtonsDisabled(true);
        action();
        setTimeout(() => setButtonsDisabled(false), 300);
    };

    if (!game || !user) {
        return null;
    }

    const handleEndTurnClick = () => {
        onEndTurn();
    };

    const handleCapture = () => {
        console.log('Заглушка для действия "Захватить"');
    };

    if (!selectedCell) {
        return (
            <div className="action-buttons">
                <div className="action-buttons__info">Клетка не выбрана</div>
                <div className="action-buttons__buttons">
                    <div
                        className={`action-buttons__button ${
                            buttonsDisabled ? 'action-buttons__button--disabled' : ''
                        }`}
                        onClick={() => handleClickWithDisable(handleEndTurnClick)}
                    >
                        <div className="action-buttons__icon">🎲</div>
                        <span className="action-buttons__label">Ход</span>
                    </div>
                </div>
            </div>
        );
    }

    const {
        coordinate_x,
        coordinate_y,
        cell_type,
        owner_id,
        unit_type,
        unit_owner_id,
        unit_health,
        unit_attack,
        unit_number,
        uint_action_number,
    } = selectedCell;

    const cellName = CELLS[cell_type]?.name || cell_type;
    const ownerName = owner_id ? userNameMap[owner_id] || '??' : 'Никто';

    let infoString = `${coordinate_y}-${coordinate_x} ${cellName} (${ownerName})`;
    if (unit_type) {
        const unitInfo = UNITS[unit_type];
        const unitOwnerName = userNameMap[unit_owner_id] || 'Монстры';
        const unitStr = `${unit_number} ${unitInfo.name} (🗡${unit_attack} ❤️${unit_health} 🦵${uint_action_number}, ${unitOwnerName})`;
        infoString += ` | ${unitStr}`;
    }

    const isMyTurn = game.active_user === user.chat_id;
    if (!isMyTurn) {
        return (
            <div className="action-buttons">
                <div className="action-buttons__info">Не ваш ход</div>
                <div className="action-buttons__buttons">
                    <div className="action-buttons__button action-buttons__button--disabled">
                        <div className="action-buttons__icon">✖️</div>
                        <span className="action-buttons__label">Не ваш ход</span>
                    </div>
                </div>
            </div>
        );
    }

    const directions = [
        {label: 'Up', icon: '⬆️', dx: 0, dy: -1},
        {label: 'Down', icon: '⬇️', dx: 0, dy: 1},
        {label: 'Left', icon: '⬅️', dx: -1, dy: 0},
        {label: 'Right', icon: '➡️', dx: 1, dy: 0},
    ];

    // Функция поиска клетки по координатам в maps
    const findCell = (x, y) => {
        return maps.find(
            (c) => c.coordinate_x === x && c.coordinate_y === y
        );
    };

    // Функция для проверки: можно ли ходить из selectedCell в cell
    const canMoveToCell = (cell) => {
        if (!cell) return false;
        const config = CELLS[cell.cell_type];
        if (!config || !config.isWalkable) {
            return false;
        }
        if (cell.unit_type && cell.unit_type.trim() !== '') {
            return false;
        }
        if (uint_action_number <= 0) {
            return false;
        }
        return true;
    };

    const handleMove = async (dx, dy) => {
        try {
            const fromX = coordinate_x;
            const fromY = coordinate_y;
            const toX = coordinate_x + dx;
            const toY = coordinate_y + dy;
            const url = `${BASE_URL}/games/${game.game_id}/moveUnit`;
            await axios.post(url, {
                fromX,
                fromY,
                toX,
                toY,
                chatId: user.chat_id,
            });
            setIsUnitMenuOpen(false);
        } catch (err) {
            console.error('Ошибка перемещения юнита', err);
        }
    };

    return (
        <div className="action-buttons">
            <div className="action-buttons__info">{infoString}</div>

            {!unit_type && (
                <div className="action-buttons__buttons">
                    <div
                        className={`action-buttons__button ${
                            buttonsDisabled ? 'action-buttons__button--disabled' : ''
                        }`}
                        onClick={() => handleClickWithDisable(handleEndTurnClick)}
                    >
                        <div className="action-buttons__icon">🎲</div>
                        <span className="action-buttons__label">Ход</span>
                    </div>
                </div>
            )}

            {unit_type && (
                <>
                    {isUnitMenuOpen ? (
                        <div className="action-buttons__buttons">
                            {/* Кнопки перемещения */}
                            {directions.map((dir) => {
                                const neighborCell = findCell(coordinate_x + dir.dx, coordinate_y + dir.dy);
                                const isAvailable = canMoveToCell(neighborCell);
                                if (!isAvailable) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={dir.label}
                                        className={`action-buttons__button ${
                                            buttonsDisabled ? 'action-buttons__button--disabled' : ''
                                        }`}
                                        onClick={() => handleClickWithDisable(() => handleMove(dir.dx, dir.dy))}
                                    >
                                        <div className="action-buttons__icon">{dir.icon}</div>
                                        <span className="action-buttons__label">Перемещение</span>
                                    </div>
                                );
                            })}

                            {/* Пример кнопки "Захватить" (условной) */}
                            <div
                                className={`action-buttons__button ${
                                    buttonsDisabled ? 'action-buttons__button--disabled' : ''
                                }`}
                                onClick={() => handleClickWithDisable(handleCapture)}
                            >
                                <div className="action-buttons__icon">🤝</div>
                                <span className="action-buttons__label">Захватить</span>
                            </div>

                            {/* Кнопка "Назад" */}
                            <div
                                className={`action-buttons__button ${
                                    buttonsDisabled ? 'action-buttons__button--disabled' : ''
                                }`}
                                onClick={() => handleClickWithDisable(() => setIsUnitMenuOpen(false))}
                            >
                                <div className="action-buttons__icon">↪️</div>
                                <span className="action-buttons__label">Назад</span>
                            </div>
                        </div>
                    ) : (
                        // --- Главное меню (открыть подменю юнита, закончить ход) ---
                        <div className="action-buttons__buttons">
                            {/* Кнопка юнита */}
                            <div
                                className={`action-buttons__button ${
                                    buttonsDisabled ? 'action-buttons__button--disabled' : ''
                                }`}
                                onClick={() =>
                                    handleClickWithDisable(() => setIsUnitMenuOpen(true))
                                }
                            >
                                <div className="action-buttons__icon">⚔️</div>
                                <span className="action-buttons__label">
                  {unit_type ? UNITS[unit_type]?.name : ''}
                </span>
                            </div>

                            {/* Кнопка "Закончить ход" */}
                            <div
                                className={`action-buttons__button ${
                                    buttonsDisabled ? 'action-buttons__button--disabled' : ''
                                }`}
                                onClick={() => handleClickWithDisable(handleEndTurnClick)}
                            >
                                <div className="action-buttons__icon">🎲</div>
                                <span className="action-buttons__label">Ход</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ActionButtons;