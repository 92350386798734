// src/components/GameSession/Cell.js

import React from 'react';
import CELLS from '../../utils/cells';

import './Cell.css';

const Cell = ({ cell, playerColorMap, isSelected, onSelect }) => {
    const {
        cell_type,
        owner_id,
        is_image,
        unit_type,
        unit_owner_id,
        unit_number
    } = cell;

    const cellConfig = CELLS[cell_type] || CELLS['grass'];
    const backgroundColor = owner_id && playerColorMap[owner_id]
        ? playerColorMap[owner_id]
        : cellConfig.color;

    const cellImagePath = `/assets/images/cells/${cell_type}.png`;
    const unitImagePath = unit_type
        ? `/assets/images/units/${unit_type}.png`
        : null;

    const style = {
        backgroundColor,
        position: 'relative',
        border: isSelected ? '1px solid ghostwhite' : 'none',
        cursor: 'pointer'
    };

    return (
        <div className="cell" style={style} onClick={onSelect}>
            {is_image && (
                <img src={cellImagePath} alt={cell_type} className="cell__image" />
            )}

            {unit_type && (
                <>
                    <img
                        src={unitImagePath}
                        alt={unit_type}
                        className="cell__unit"
                    />

                    <div
                        className="cell__unit-owner-marker"
                        style={{
                            color: playerColorMap[unit_owner_id] || '#888'
                        }}
                    >
                        {unit_number}
                    </div>
                </>
            )}
        </div>
    );
};

export default Cell;