// src/components/GameSession/GameField.js

import React, { useMemo } from 'react';
import Cell from './Cell';
import './GameField.css';

const GameField = ({ cells, players, playerColorMap, selectedCell, onSelectCell }) => {
    const mapSize = useMemo(() => {
        if (!cells || cells.length === 0) return 10;
        const maxX = Math.max(...cells.map((c) => c.coordinate_x));
        return maxX || 10;
    }, [cells]);

    const fieldStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(${mapSize}, 1fr)`,
        gap: '1px',
        width: '100%',
        maxWidth: '410px',
        margin: '0 auto'
    };

    return (
        <div className="game-field" style={fieldStyle}>
            {cells.map((cell) => {
                const key = `${cell.coordinate_x}-${cell.coordinate_y}`;
                const isSelected = selectedCell
                    && selectedCell.coordinate_x === cell.coordinate_x
                    && selectedCell.coordinate_y === cell.coordinate_y;

                return (
                    <Cell
                        key={key}
                        cell={cell}
                        playerColorMap={playerColorMap}
                        isSelected={isSelected}
                        onSelect={() => onSelectCell(cell)}
                    />
                );
            })}
        </div>
    );
};

export default GameField;