// src/contexts/SocketContext.js

import React, { createContext, useEffect } from 'react';
import socket from '../socket';
import { useSelector } from 'react-redux';

export const SocketContext = createContext(socket);

export const SocketProvider = ({ children }) => {
    const currentUserState = useSelector((state) => state.currentUser);
    const { user } = currentUserState;

    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }

        // Отправка register при подключении
        const handleConnect = () => {
            console.log('Socket connected:', socket.id);
            if (user && user.chat_id) {
                socket.emit('register', { chatId: user.chat_id });
            }
        };

        socket.on('connect', handleConnect);

        return () => {
            socket.off('connect', handleConnect);
        };
    }, [user]);

    // Дополнительный эффект для отслеживания изменений пользователя
    useEffect(() => {
        if (user && user.chat_id && socket.connected) {
            socket.emit('register', { chatId: user.chat_id });
        }
    }, [user]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};