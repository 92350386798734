// src/components/Login/Login/Login.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/userActions';
import { useNavigate } from 'react-router-dom';
import texts from '../../utils/texts';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './Login.css';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gamename, setGamename] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const currentUserState = useSelector((state) => state.currentUser);
    const { user, error, loading } = currentUserState;

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginError('');

        try {
            await dispatch(login(gamename, password));
        } catch (error) {
            setLoginError(error.message || texts.loginError);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            if (user.status === 'start') {
                navigate('/registration');
            } else {
                navigate('/mainmenu');
            }
        } else if (error) {
            setLoginError(error);
        }
    }, [user, error, navigate]);

    return (
        <div className="login">
            <p className="index__message">
                {texts.loginMessage}{' '}
                <a href="https://t.me/IvidersBot">{texts.loginLink}</a>
            </p>
            <form className="index__form" onSubmit={handleLogin}>
                <h2 className="index__header-h2">{texts.loginFormTitle}</h2>
                <div className="login__field">
                    <label htmlFor="gamename">{texts.gamenameLabel}</label>
                    <input
                        type="text"
                        id="gamename"
                        value={gamename}
                        onChange={(e) => setGamename(e.target.value)}
                        required
                    />
                </div>
                <div className="login__field">
                    <label htmlFor="password">{texts.passwordLabel}</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <p className="login__note">{texts.passwordNote}</p>
                {loginError && <p className="index__error">{loginError}</p>}
                <button type="submit" className="index__button">
                    {texts.loginButton}
                </button>
            </form>
            {isLoading && <LoadingSpinner />}
        </div>
    );
};

export default Login;