// src/constants/gameSessionConstants.js

export const FETCH_GAME_SESSION_REQUEST = 'FETCH_GAME_SESSION_REQUEST';
export const FETCH_GAME_SESSION_SUCCESS = 'FETCH_GAME_SESSION_SUCCESS';
export const FETCH_GAME_SESSION_FAILURE = 'FETCH_GAME_SESSION_FAILURE';

export const LEAVE_GAME_SESSION_REQUEST = 'LEAVE_GAME_SESSION_REQUEST';
export const LEAVE_GAME_SESSION_SUCCESS = 'LEAVE_GAME_SESSION_SUCCESS';
export const LEAVE_GAME_SESSION_FAILURE = 'LEAVE_GAME_SESSION_FAILURE';

export const FETCH_GAME_MESSAGES_REQUEST = 'FETCH_GAME_MESSAGES_REQUEST';
export const FETCH_GAME_MESSAGES_SUCCESS = 'FETCH_GAME_MESSAGES_SUCCESS';
export const FETCH_GAME_MESSAGES_FAILURE = 'FETCH_GAME_MESSAGES_FAILURE';

export const FETCH_GAME_MAPS_REQUEST = 'FETCH_GAME_MAPS_REQUEST';
export const FETCH_GAME_MAPS_SUCCESS = 'FETCH_GAME_MAPS_SUCCESS';
export const FETCH_GAME_MAPS_FAILURE = 'FETCH_GAME_MAPS_FAILURE';

