// src/components/GameSession/GameChatMessage.js

import React from 'react';

import './GameChatMessage.css';

const GameChatMessage = ({ message, color }) => {
    return (
        <div className="game-chat-message">
            <strong style={{ color: color || 'inherit' }}>{message.sender_name}:</strong> {message.message}
        </div>
    );
};

export default GameChatMessage;