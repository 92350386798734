// src/components/GameSession/TopPanel.js

import React from 'react';
import './TopPanel.css';

const TopPanel = ({
                      resources, color, currentTurn, totalTurns,
                      activeUser, players, timeLeft
                  }) => {
    const activePlayer = players.find(player => player.chat_id === activeUser);
    const activeUserDisplay = activePlayer ? `${activePlayer.gamename}` : activeUser;

    return (
        <div className="top-panel">
            <div className="top-panel__resources">
                <span>🌲 {resources.wood} (+0)</span>
                <span>🥔 {resources.food} (+0)</span>
                <span>🎲 {currentTurn}/{totalTurns}</span>
                <span>⏳ {timeLeft}</span>

                <div
                    className="top-panel__active-user"
                    style={{color: activePlayer ? activePlayer.color : '#dddddd'}}
                >
                    ходит {activeUserDisplay}
                </div>
            </div>
        </div>
    );
};

export default TopPanel;