// src/constants/userConstants.js

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FETCH_USER_STATUS_REQUEST = 'FETCH_USER_STATUS_REQUEST';
export const FETCH_USER_STATUS_SUCCESS = 'FETCH_USER_STATUS_SUCCESS';
export const FETCH_USER_STATUS_FAILURE = 'FETCH_USER_STATUS_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_IS_BOT_BANNED_REQUEST = 'UPDATE_IS_BOT_BANNED_REQUEST';
export const UPDATE_IS_BOT_BANNED_SUCCESS = 'UPDATE_IS_BOT_BANNED_SUCCESS';
export const UPDATE_IS_BOT_BANNED_FAILURE = 'UPDATE_IS_BOT_BANNED_FAILURE';

export const FETCH_VIEWED_USER_REQUEST = 'FETCH_VIEWED_USER_REQUEST';
export const FETCH_VIEWED_USER_SUCCESS = 'FETCH_VIEWED_USER_SUCCESS';
export const FETCH_VIEWED_USER_FAILURE = 'FETCH_VIEWED_USER_FAILURE';

export const FETCH_VIEWED_USERS_REQUEST = 'FETCH_VIEWED_USERS_REQUEST';
export const FETCH_VIEWED_USERS_SUCCESS = 'FETCH_VIEWED_USERS_SUCCESS';
export const FETCH_VIEWED_USERS_FAILURE = 'FETCH_VIEWED_USERS_FAILURE';

