// src/components/GameRooms/RoomDetail/RoomDetail.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchRoomDetails,
    leaveRoom,
    kickPlayer,
    startGame,
    playerJoined,
    playerLeft,
    playerKicked,
    userKicked
} from '../../../actions/gameRoomActions';
import { useParams, useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../contexts/SocketContext';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import RoomInfo from './RoomInfo';
import PlayerList from './PlayerList';
import Controls from './Controls';
import Chat from './Chat';
import texts from '../../../utils/texts';

const RoomDetail = () => {
    const socket = useContext(SocketContext);
    const { room_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentRoom, loading, error } = useSelector((state) => state.gameRooms);
    const { user } = useSelector((state) => state.currentUser);

    const [chatMessages, setChatMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (!user?.chat_id) return;
        if (user.current_room_id !== Number(room_id)) {
            return;
        }
        dispatch(fetchRoomDetails(room_id));
    }, [dispatch, room_id, user?.current_room_id]);

    useEffect(() => {
        if (!user?.chat_id) return;

        const handlePreviousMessages = (messages) => {
            setChatMessages(messages);
        };

        const handleNewMessage = (message) => {
            setChatMessages((prev) => [...prev, message]);
        };

        const handlePlayerJoined = (player) => {
            dispatch(playerJoined(player));
            const message = `${player.title} ${player.gamename} теперь в комнате.`;
            setChatMessages((prev) => [...prev, { message, sender_name: 'Система' }]);
        };

        const handlePlayerLeft = (player) => {
            dispatch(playerLeft(player));
            const message = `${player.title} ${player.gamename} покинул(а) комнату.`;
            setChatMessages((prev) => [...prev, { message, sender_name: 'Система' }]);
        };

        const handlePlayerKicked = (player) => {
            dispatch(playerKicked(player));
            const message = `${player.title} ${player.gamename} был(а) выкинут(а).`;
            setChatMessages((prev) => [...prev, { message, sender_name: 'Система' }]);
        };

        const handleKicked = () => {
            dispatch(userKicked());
            navigate('/mainmenu');
        };

        const handleError = (err) => {
            console.error('Socket error:', err);
        };

        const handleGameStarted = (game) => {
            if (game.player_chat_ids.includes(user.chat_id)) {
                navigate(`/game/${game.game_id}`);
            }
        };

        const handleConnect = () => {
            socket.emit('joinRoom', { roomId: room_id, chatId: user.chat_id });
        };

        const handleDisconnect = () => {};

        socket.on('connect', handleConnect);
        socket.on('previousMessages', handlePreviousMessages);
        socket.on('newMessage', handleNewMessage);
        socket.on('playerJoined', handlePlayerJoined);
        socket.on('playerLeft', handlePlayerLeft);
        socket.on('playerKicked', handlePlayerKicked);
        socket.on('kicked', handleKicked);
        socket.on('error', handleError);
        socket.on('disconnect', handleDisconnect);
        socket.on('gameStarted', handleGameStarted);

        if (socket.connected) {
            socket.emit('joinRoom', { roomId: room_id, chatId: user.chat_id });
        }

        return () => {
            socket.off('connect', handleConnect);
            socket.off('previousMessages', handlePreviousMessages);
            socket.off('newMessage', handleNewMessage);
            socket.off('playerJoined', handlePlayerJoined);
            socket.off('playerLeft', handlePlayerLeft);
            socket.off('playerKicked', handlePlayerKicked);
            socket.off('kicked', handleKicked);
            socket.off('error', handleError);
            socket.off('disconnect', handleDisconnect);
            socket.off('gameStarted', handleGameStarted);
            socket.emit('leaveRoom', { roomId: room_id, chatId: user.chat_id });
        };
    }, [socket, room_id, user, dispatch, navigate]);

    const handleLeaveRoom = async () => {
        try {
            await dispatch(leaveRoom(room_id));
            navigate('/mainmenu');
        } catch (err) {
            console.error('Ошибка при покидании комнаты:', err);
        }
    };

    const handleKickPlayer = async (chatId) => {
        try {
            await dispatch(kickPlayer(room_id, chatId, user.chat_id));
            dispatch(fetchRoomDetails(room_id));
        } catch (err) {
            console.error('Ошибка при выгнании игрока:', err);
        }
    };

    const handleStartGame = async () => {
        try {
            await dispatch(startGame(room_id));
        } catch (err) {
            console.error('Ошибка при запуске игры:', err);
        }
    };

    const handleSendMessage = () => {
        if (!socket) {
            return;
        }
        const trimmedMessage = newMessage.trim();
        if (trimmedMessage === '') return;
        const messageData = {
            roomId: room_id,
            chatId: user.chat_id,
            message: trimmedMessage,
        };

        socket.emit('sendMessage', messageData, (ack) => {
            if (ack?.error) {
                console.error('Error sending message:', ack.error);
            }
        });

        setNewMessage('');
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <p className="room-detail__error">{error}</p>;
    }

    if (!currentRoom) {
        return <p className="room-detail__error">Комната не найдена.</p>;
    }

    const isPlayerInRoom = currentRoom.players?.some(player => player.chat_id === user.chat_id);

    if (!isPlayerInRoom) {
        return (
            <div className="room-detail__not-in-room">
                <p>{texts.roomDetail.youAreNotInRoom}</p>
                <button className="index__button" onClick={() => navigate('/mainmenu')}>Назад</button>
            </div>
        );
    }

    const isHost = currentRoom.host_chat_id === user.chat_id;

    return (
        <div className="room-detail">
            <h1 className="room-detail__title">
                {texts.roomDetail.title} {currentRoom.room_name}
            </h1>
            <RoomInfo room={currentRoom} />
            <PlayerList
                players={currentRoom.players}
                maxPlayers={currentRoom.max_players}
                isHost={isHost}
                currentUser={user}
                onKickPlayer={handleKickPlayer}
            />
            <Controls
                isHost={isHost}
                onStartGame={handleStartGame}
                onLeaveRoom={handleLeaveRoom}
            />
            <Chat
                messages={chatMessages}
                newMessage={newMessage}
                onSendMessage={handleSendMessage}
                onChangeMessage={setNewMessage}
                messagesEndRef={messagesEndRef}
            />
        </div>
    );
};

export default RoomDetail;