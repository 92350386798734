// src/constants/gameRoomConstants.js

export const CREATE_ROOM_REQUEST = 'CREATE_ROOM_REQUEST';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE';

export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';

export const JOIN_ROOM_REQUEST = 'JOIN_ROOM_REQUEST';
export const JOIN_ROOM_SUCCESS = 'JOIN_ROOM_SUCCESS';
export const JOIN_ROOM_FAILURE = 'JOIN_ROOM_FAILURE';

export const FETCH_ROOM_DETAILS_REQUEST = 'FETCH_ROOM_DETAILS_REQUEST';
export const FETCH_ROOM_DETAILS_SUCCESS = 'FETCH_ROOM_DETAILS_SUCCESS';
export const FETCH_ROOM_DETAILS_FAILURE = 'FETCH_ROOM_DETAILS_FAILURE';

export const LEAVE_ROOM_REQUEST = 'LEAVE_ROOM_REQUEST';
export const LEAVE_ROOM_SUCCESS = 'LEAVE_ROOM_SUCCESS';
export const LEAVE_ROOM_FAILURE = 'LEAVE_ROOM_FAILURE';

export const KICK_PLAYER_REQUEST = 'KICK_PLAYER_REQUEST';
export const KICK_PLAYER_SUCCESS = 'KICK_PLAYER_SUCCESS';
export const KICK_PLAYER_FAILURE = 'KICK_PLAYER_FAILURE';

export const START_GAME_REQUEST = 'START_GAME_REQUEST';
export const START_GAME_SUCCESS = 'START_GAME_SUCCESS';
export const START_GAME_FAILURE = 'START_GAME_FAILURE';

export const PLAYER_JOINED = 'PLAYER_JOINED';
export const PLAYER_LEFT = 'PLAYER_LEFT';
export const PLAYER_KICKED = 'PLAYER_KICKED';
export const USER_KICKED = 'USER_KICKED';
