// src/reducers/gameSessionReducer.js

import {
    FETCH_GAME_SESSION_REQUEST,
    FETCH_GAME_SESSION_SUCCESS,
    FETCH_GAME_SESSION_FAILURE,
    FETCH_GAME_MAPS_REQUEST,
    FETCH_GAME_MAPS_SUCCESS,
    FETCH_GAME_MAPS_FAILURE
} from '../constants/gameSessionConstants';
import {
    FETCH_VIEWED_USERS_FAILURE,
    FETCH_VIEWED_USERS_REQUEST,
    FETCH_VIEWED_USERS_SUCCESS
} from "../constants/userConstants";

const initialState = {
    loading: false,
    game: null,
    messages: [],
    players: [],
    error: null,
    playersLoading: false,
    playersError: null,
    loadingMaps: false,
    maps: [],
    errorMaps: null
};

const gameSessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GAME_SESSION_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_GAME_SESSION_SUCCESS:
            return { ...state, loading: false, game: action.payload };
        case FETCH_GAME_SESSION_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_VIEWED_USERS_REQUEST:
            return { ...state, playersLoading: true, playersError: null };
        case FETCH_VIEWED_USERS_SUCCESS:
            return { ...state, playersLoading: false, players: action.payload };
        case FETCH_VIEWED_USERS_FAILURE:
            return { ...state, playersLoading: false, playersError: action.payload };

        case FETCH_GAME_MAPS_REQUEST:
            return { ...state, loadingMaps: true, errorMaps: null };
        case FETCH_GAME_MAPS_SUCCESS:
            return { ...state, loadingMaps: false, maps: action.payload };
        case FETCH_GAME_MAPS_FAILURE:
            return { ...state, loadingMaps: false, errorMaps: action.payload };
        case 'SOCKET_GAME_UPDATED':
            return {
                ...state,
                game: action.payload.game,
                maps: action.payload.maps,
                players: action.payload.players,
            };

        default:
            return state;
    }
};

export default gameSessionReducer;