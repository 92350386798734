// src/components/GameRooms/RoomDetail/PlayerItem.js

import React from 'react';
import './PlayerItem.css';

const PlayerItem = ({player, isHost, currentUser, onKickPlayer}) => {
    const backgroundColor = player.color || '#3a3a3a';

    return (
        <li className="player-item" style={{backgroundColor}}>
            <div className="player-item__info">
                {/* Аватар пользователя */}
                <img
                    src={player.user_pic ? `/assets/images/profile/faces/${player.user_pic}.png` : '/assets/images/profile/faces/default.png'}
                    alt={player.gamename}
                    className="player-item__avatar"
                />
                {/* Изображение фракции */}
                <img
                    src={`/assets/images/fractions/${player.fraction_name}.png`}
                    alt={player.fraction_name}
                    className="player-item__fraction"
                />
                {/* Имя игрока */}
                <span className="player-item__name">
                    {player.gamename}
                </span>
            </div>
            {isHost && player.chat_id !== currentUser.chat_id && (
                <button
                    className="player-item__kick-button"
                    onClick={() => onKickPlayer(player.chat_id)}
                >
                    Кик
                </button>
            )}
        </li>
    );
};

export default PlayerItem;