// src/actions/gameSessionActions.js

import axios from 'axios';
import {
    FETCH_GAME_SESSION_REQUEST,
    FETCH_GAME_SESSION_SUCCESS,
    FETCH_GAME_SESSION_FAILURE,
    FETCH_GAME_MAPS_REQUEST,
    FETCH_GAME_MAPS_SUCCESS,
    FETCH_GAME_MAPS_FAILURE
} from '../constants/gameSessionConstants';

export const BASE_URL = 'https://inviders.kitezhgrad.fun/api';

export const fetchGameSession = (gameId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GAME_SESSION_REQUEST });
        const { data } = await axios.get(`${BASE_URL}/games/${gameId}`);
        dispatch({ type: FETCH_GAME_SESSION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_GAME_SESSION_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при загрузке игровой сессии',
        });
    }
};

export const fetchGameMaps = (gameId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_GAME_MAPS_REQUEST });
        const { data } = await axios.get(`${BASE_URL}/maps/game/${gameId}/cells`);
        dispatch({ type: FETCH_GAME_MAPS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_GAME_MAPS_FAILURE,
            payload: error.response?.data?.error || 'Ошибка при загрузке карты',
        });
    }
};