// src/actions/gameRoomActions.js

import axios from 'axios';
import {
    CREATE_ROOM_REQUEST,
    CREATE_ROOM_SUCCESS,
    CREATE_ROOM_FAILURE,
    FETCH_ROOMS_REQUEST,
    FETCH_ROOMS_SUCCESS,
    FETCH_ROOMS_FAILURE,
    JOIN_ROOM_REQUEST,
    JOIN_ROOM_SUCCESS,
    JOIN_ROOM_FAILURE,
    FETCH_ROOM_DETAILS_REQUEST,
    FETCH_ROOM_DETAILS_SUCCESS,
    FETCH_ROOM_DETAILS_FAILURE,
    LEAVE_ROOM_REQUEST,
    LEAVE_ROOM_SUCCESS,
    LEAVE_ROOM_FAILURE,
    KICK_PLAYER_REQUEST,
    KICK_PLAYER_SUCCESS,
    KICK_PLAYER_FAILURE,
    START_GAME_REQUEST,
    START_GAME_SUCCESS,
    START_GAME_FAILURE,
    PLAYER_JOINED,
    PLAYER_LEFT,
    PLAYER_KICKED,
    USER_KICKED,
} from '../constants/gameRoomConstants';

import {UPDATE_USER_SUCCESS} from '../constants/userConstants';

export const BASE_URL = 'https://inviders.kitezhgrad.fun/api';

export const createRoom = (roomData) => async (dispatch, getState) => {
    try {
        dispatch({type: CREATE_ROOM_REQUEST});

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.post(`${BASE_URL}/rooms`, roomData, config);

        dispatch({
            type: CREATE_ROOM_SUCCESS,
            payload: data,
        });

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: {...data.host, status: 'in_room', current_room_id: data.room_id},
        });

    } catch (error) {
        dispatch({
            type: CREATE_ROOM_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const fetchRooms = () => async (dispatch) => {
    try {
        dispatch({type: FETCH_ROOMS_REQUEST});

        const {data} = await axios.get(`${BASE_URL}/rooms`);

        dispatch({
            type: FETCH_ROOMS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_ROOMS_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const joinRoom = (roomId, password = null) => async (dispatch, getState) => {
    try {
        dispatch({type: JOIN_ROOM_REQUEST});

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const payload = {chat_id: getState().currentUser.user.chat_id};
        if (password) {
            payload.password = password;
        }

        const {data} = await axios.post(`${BASE_URL}/rooms/${roomId}/join`, payload, config);

        dispatch({
            type: JOIN_ROOM_SUCCESS,
            payload: data,
        });

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: {...getState().currentUser.user, status: 'in_room', current_room_id: roomId},
        });

    } catch (error) {
        dispatch({
            type: JOIN_ROOM_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const fetchRoomDetails = (roomId) => async (dispatch) => {
    try {
        dispatch({type: FETCH_ROOM_DETAILS_REQUEST});

        const {data} = await axios.get(`${BASE_URL}/rooms/${roomId}`);

        dispatch({
            type: FETCH_ROOM_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_ROOM_DETAILS_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const leaveRoom = (roomId) => async (dispatch, getState) => {
    try {
        dispatch({type: LEAVE_ROOM_REQUEST});

        const {data} = await axios.post(`${BASE_URL}/rooms/${roomId}/leave`, {
            chat_id: getState().currentUser.user.chat_id,
        });

        dispatch({
            type: LEAVE_ROOM_SUCCESS,
            payload: data,
        });

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: {...getState().currentUser.user, status: 'ready', current_room_id: null},
        });

    } catch (error) {
        dispatch({
            type: LEAVE_ROOM_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const kickPlayer = (roomId, chatId, hostChatId) => async (dispatch) => {
    try {
        dispatch({type: KICK_PLAYER_REQUEST});

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const payload = {chat_id: chatId, host_chat_id: hostChatId};

        const {data} = await axios.post(`${BASE_URL}/rooms/${roomId}/kick`, payload, config);

        dispatch({
            type: KICK_PLAYER_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: KICK_PLAYER_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const startGame = (roomId) => async (dispatch, getState) => {
    try {
        dispatch({type: START_GAME_REQUEST});

        const {data} = await axios.post(`${BASE_URL}/rooms/${roomId}/start`, {
            chat_id: getState().currentUser.user.chat_id,
        });

        dispatch({
            type: START_GAME_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: START_GAME_FAILURE,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
};

export const playerJoined = (player) => ({
    type: PLAYER_JOINED,
    payload: player,
});

export const playerLeft = (player) => ({
    type: PLAYER_LEFT,
    payload: player,
});

export const playerKicked = (player) => ({
    type: PLAYER_KICKED,
    payload: player,
});

export const userKicked = (data) => ({
    type: USER_KICKED,
    payload: data,
});