// src/utils/texts.js

const texts = {
    // Главное меню
    mainMenu: {
        title: 'Посягатели',
        createGame: 'Создать комнату',
        findGame: 'Найти комнату',
        profile: 'Профиль',
    },

    // Авторизация
    loginMessage: 'Регистрация только через Телеграм-бота',
    loginLink: 'Перейти к боту',
    loginFormTitle: 'Вход по токену',
    gamenameLabel: 'Игровое имя',
    passwordLabel: 'Пароль',
    passwordNote: 'Пароль можно получить в телеграм-боте',
    loginButton: 'Войти',
    loginError: 'Ошибка входа',

    // Регистрация
    registrationTitle: 'Регистрация',
    createGamenameLabel: 'Придумайте игровое имя (логин)',
    gamenameError:
        'Имя должно содержать 2-10 символов, только кириллица (включая ё), латиница, цифры и нижние подчеркивания. Без пробелов.',
    selectGenderLabel: 'Выберите, в каком роде будет ваш титул',
    maleLabel: 'Мужской',
    femaleLabel: 'Женский',
    selectAvatarLabel: 'Выберите аватар',
    selectFractionLabel: 'Выбор фракции',
    fractionNote: 'Влияет на внешний вид.',
    fractionDescription: 'Описание фракции',
    saveButton: 'Сохранить',

    createRoom: {
        title: 'Создать комнату',
        roomNameLabel: 'Название комнаты',
        roomNamePlaceholder: 'Максимум 15 символов',
        mapSizeLabel: 'Размер карты (например, 10 для 10x10)',
        mapSizePlaceholder: '19',
        movesPerPlayerLabel: 'Количество ходов на игрока',
        movesPerPlayerPlaceholder: '50',
        isOpenLabel: 'Открытая комната',
        isPrivateLabel: 'Приватная комната',
        passwordLabel: 'Пароль для комнаты',
        maxPlayersLabel: 'Максимальное количество игроков',
        maxPlayersPlaceholder: '8',
        createButton: 'Создать',
        createError: 'Ошибка при создании комнаты',
        privacyNote: 'Если комната приватная, требуется пароль для присоединения.',
    },

    // Список комнат
    roomList: {
        title: 'Список комнат',
        joinButton: 'Войти',
        refreshButton: 'Обновить',
        enterPassword: 'Введите пароль',
        enterPasswordButton: 'Войти',
        noRooms: 'Комнат нет',
        loading: 'Загрузка комнат...',
    },

    // Детали комнаты
    roomDetail: {
        title: 'Комната',
        mapSize: 'Размер карты',
        movesPerPlayer: 'Ходов на игрока',
        status: 'Статус',
        status_open: 'открытая',
        status_private: 'приватная',
        slot: 'Свободный слот',
        loadingGamers: 'Загрузка списка игроков...',
        readyButton: '☑️',
        notReadyButton: '✖️',
        kickButton: 'Выгнать',
        startGameButton: 'Старт',
        chatPlaceholder: 'Введите сообщение...',
        sendButton: 'Отправить',
        allReady: 'Все игроки готовы',
        notAllReady: 'Не все игроки готовы',
        exit: 'Выйти',
        youAreNotInRoom: 'Вы не находитесь в комнате'
    },
    TopPanel: {
    },

    // Ошибки и уведомления
    errors: {
        alreadyInRoom: 'Вы уже находитесь в комнате.',
        roomNotFound: 'Комната не найдена.',
        incorrectPassword: 'Неверный пароль.',
        roomFull: 'Комната заполнена.',
    },

    // Успех
    success: {
        roomCreated: 'Комната успешно создана.',
        joinedRoom: 'Вы успешно присоединились к комнате.',
        leftRoom: 'Вы покинули комнату.',
    },

    // Загрузка
    loadingSpinner: 'Загрузка...',

    // Фракции
    mkora: {
        name: 'Разбойники Мкоры',
        description: 'Среди холмистых закоулков бывшей империи бандитские кланы объединились под властью харизматичного Разбойничьего князя Нгэ Рэтсё Беглеца. Их цель – выжить множества врагов.',
    },
    mitsanaRinsona: {
        name: 'Княжество Митсана-Ринсона',
        description: 'Основанное стратегом Бенача Лаксё, это княжество бюрократов и учёных выросло из пепла бывшей империи. Лаксё начал борьбу против кочевников и сумел замедлить натиск врага, чтобы возродить империю.',
    },
    veengo: {
        name: 'Княжество Вээнгё',
        description: 'Под лидерством братьев Тсави и Атсы, воинственное княжество Вээнгё стало центром сопротивления кочевникам. Благодаря наёмникам, оно утвердило позиции, расширив владения и обеспечив независимость от Митсаны.',
    },
    blackSteppeRiders: {
        name: 'Наездники Чёрной степи',
        description: 'Эти суровые кочевники, расколовшиеся после гражданской войны в Орде наездников, стали опасными врагами соседних государств. Хан Япурату хочет захватить их, чтобы у его народа был выход к морю.',
    },

    // Титулы
    titles: {
        dust_slave: {
            code: 'dust_slave',
            male: 'Пыльный раб',
            female: 'Пыльная рабыня',
        }
    }
};

export default texts;
