// src/components/GameRooms/RoomDetail/Controls.js

import React from 'react';
import texts from '../../../utils/texts';
import './Controls.css';

const Controls = ({
                      isHost,
                      onStartGame,
                      onLeaveRoom,
                  }) => {
    return (
        <div className="controls">
            {isHost && (
                <button
                    className={`controls__start-button index__button`}
                    onClick={onStartGame}
                >
                    {texts.roomDetail.startGameButton}
                </button>
            )}
            <button className="controls__leave-button index__button" onClick={onLeaveRoom}>
                {texts.roomDetail.exit}
            </button>
        </div>
    );
};

export default Controls;
