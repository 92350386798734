// src/components/GameRooms/CreateRoom/CreateRoom.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRoom } from '../../../actions/gameRoomActions';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import texts from '../../../utils/texts';

import './CreateRoom.css';

const CreateRoom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.currentUser);
    const { loading, error, currentRoom } = useSelector((state) => state.gameRooms);

    const [roomName, setRoomName] = useState('');
    const [mapSize, setMapSize] = useState(10);
    const [movesPerPlayer, setMovesPerPlayer] = useState(40);
    const [isOpen, setIsOpen] = useState(true);
    const [password, setPassword] = useState('');
    const [maxPlayers, setMaxPlayers] = useState(6);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user.status === 'in_room') {
            navigate(`/game-rooms/${user.current_room_id}`);
        }
    }, [user, navigate]);

    useEffect(() => {
        if (currentRoom) {
            navigate(`/game-rooms/${currentRoom.room_id}`);
        }
    }, [currentRoom, navigate]);

    const validate = () => {
        const newErrors = {};

        if (!roomName || roomName.length > 15) {
            newErrors.roomName = 'Название комнаты должно быть от 1 до 15 символов.';
        }

        if (mapSize < 9 || mapSize > 19) {
            newErrors.mapSize = 'Размер карты должен быть от 9 до 19.';
        }

        if (movesPerPlayer < 40 || movesPerPlayer > 150) {
            newErrors.movesPerPlayer = 'Количество ходов должно быть от 40 до 150.';
        }

        if (maxPlayers < 2 || maxPlayers > 8) {
            newErrors.maxPlayers = 'Количество игроков должно быть от 2 до 8.';
        }

        if (!isOpen) {
            if (!password) {
                newErrors.password = 'Пароль обязателен для приватной комнаты.';
            } else if (password.length < 1 || password.length > 10) {
                newErrors.password = 'Пароль должен быть от 1 до 10 символов.';
            }
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        const roomData = {
            room_name: roomName,
            map_size: mapSize,
            moves_per_player: movesPerPlayer,
            is_open: isOpen,
            password: isOpen ? null : password,
            max_players: maxPlayers,
            host_chat_id: user.chat_id,
        };

        try {
            await dispatch(createRoom(roomData));
        } catch (err) {
            console.error('Ошибка при создании комнаты:', err);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="create-room">
            <h1 className="create-room__title">{texts.createRoom.title}</h1>
            <form className="create-room__form" onSubmit={handleCreate}>
                <div className="create-room__field">
                    <label htmlFor="roomName" className="create-room__label">
                        {texts.createRoom.roomNameLabel}
                    </label>
                    <input
                        type="text"
                        id="roomName"
                        className="create-room__input"
                        value={roomName}
                        onChange={(e) => setRoomName(e.target.value)}
                        maxLength={15}
                        required
                    />
                    {errors.roomName && <p className="create-room__error">{errors.roomName}</p>}
                </div>

                <div className="create-room__field">
                    <label htmlFor="mapSize" className="create-room__label">
                        {texts.createRoom.mapSizeLabel}
                    </label>
                    <input
                        type="number"
                        id="mapSize"
                        className="create-room__input"
                        value={mapSize}
                        onChange={(e) => setMapSize(Number(e.target.value))}
                        min={9}
                        max={19}
                        required
                    />
                    {errors.mapSize && <p className="create-room__error">{errors.mapSize}</p>}
                </div>

                <div className="create-room__field">
                    <label htmlFor="movesPerPlayer" className="create-room__label">
                        {texts.createRoom.movesPerPlayerLabel}
                    </label>
                    <input
                        type="number"
                        id="movesPerPlayer"
                        className="create-room__input"
                        value={movesPerPlayer}
                        onChange={(e) => setMovesPerPlayer(Number(e.target.value))}
                        min={40}
                        max={150}
                        required
                    />
                    {errors.movesPerPlayer && <p className="create-room__error">{errors.movesPerPlayer}</p>}
                </div>

                <div className="create-room__field">
                    <label className="create-room__label">{texts.createRoom.isOpenLabel}</label>
                    <div className="create-room__toggle">
                        <label className="create-room__switch">
                            <input
                                type="checkbox"
                                checked={isOpen}
                                onChange={(e) => setIsOpen(e.target.checked)}
                            />
                            <span className="create-room__slider"></span>
                        </label>
                        <span>{isOpen ? 'Открытая' : 'Приватная'}</span>
                    </div>
                </div>

                {!isOpen && (
                    <div className="create-room__field">
                        <label htmlFor="password" className="create-room__label">
                            {texts.createRoom.passwordLabel}
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="create-room__input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={10}
                            required={!isOpen}
                        />
                        {errors.password && <p className="create-room__error">{errors.password}</p>}
                    </div>
                )}

                <div className="create-room__field">
                    <label htmlFor="maxPlayers" className="create-room__label">
                        {texts.createRoom.maxPlayersLabel}
                    </label>
                    <input
                        type="number"
                        id="maxPlayers"
                        className="create-room__input"
                        value={maxPlayers}
                        onChange={(e) => setMaxPlayers(Number(e.target.value))}
                        min={2}
                        max={8}
                        required
                    />
                    {errors.maxPlayers && <p className="create-room__error">{errors.maxPlayers}</p>}
                </div>

                {error && <p className="create-room__error">{error}</p>}

                <div className="create-room__buttons">
                    <button type="submit" className="create-room__button" disabled={loading}>
                        {texts.createRoom.createButton}
                    </button>
                    <button type="button" className="create-room__button" onClick={handleBack}>
                        Назад
                    </button>
                </div>

                {loading && <LoadingSpinner />}
            </form>
        </div>
    );
};

export default CreateRoom;