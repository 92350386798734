// src/utils/units.js

const UNITS = {
    spearmen: {
        name: "Копейщики",
        health: 10,
        attack: 6
    },
};

export default UNITS;
