// src/utils/cells.js

const CELLS = {
    grass: {
        color: '#3e6746',
        name: "Поле",
        description: "Трава — стандартная клетка.",
        isWalkable: true,
        isBuilding: false,
    },
    forest: {
        color: '#3c6444',
        name: "Лес",
        description: "Лес — лесная клетка.",
        isWalkable: true,
        isBuilding: false,
        wood: 1
    },
    swamp: {
        color: '#3c6444',
        name: "Болото",
        description: "Болото — клетка с болотом.",
        isWalkable: true,
        isBuilding: false,
    },
    water: {
        color: 'darkblue',
        name: "Вода",
        description: "Вода — непроходимая клетка.",
        isWalkable: false,
        isBuilding: false,
    },
    mountain: {
        color: '#543d2b',
        name: "Горы",
        description: "Горы — непроходимая клетка.",
        isWalkable: false,
        isBuilding: false,
    },

    base_1: {
        color: '#3e6746',
        name: "База 3/3",
        description: "Целая база игрока.",
        isWalkable: true,
        isBuilding: true,
        food: 5
    },
};

export default CELLS;
