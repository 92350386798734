// src/components/GameSession/GameChat.js

import React, { useEffect } from 'react';

import GameChatMessage from './GameChatMessage';

import './GameChat.css';

const GameChat = ({
                      messages,
                      newMessage,
                      onSendMessage,
                      onChangeMessage,
                      messagesEndRef,
                      playerColorMap,
                  }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSendMessage();
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, messagesEndRef]);

    return (
        <div className="game-chat">
            <div className="game-chat__messages">
                {messages.map((msg) => (
                    <GameChatMessage
                        key={msg.id}
                        message={msg}
                        color={msg.system ? null : playerColorMap[msg.sender_chat_id]}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="game-chat__input">
                <input
                    type="text"
                    className="game-chat__text-input"
                    value={newMessage}
                    onChange={(e) => onChangeMessage(e.target.value)}
                    placeholder="Введите сообщение..."
                    onKeyDown={handleKeyDown}
                />
                <button className="game-chat__send-button" onClick={onSendMessage}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default GameChat;