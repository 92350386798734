// src/components/GameRooms/RoomList/RoomList.js

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRooms, joinRoom } from '../../../actions/gameRoomActions';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import texts from '../../../utils/texts';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../contexts/SocketContext';
import './RoomList.css';

const RoomList = () => {
    const socket = useContext(SocketContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { rooms, loading, error } = useSelector((state) => state.gameRooms);

    const [passwords, setPasswords] = useState({});
    const [joinErrors, setJoinErrors] = useState({});
    const [isJoining, setIsJoining] = useState({});

    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }

        const handleUpdateRoomList = (updatedRooms) => {
            dispatch({ type: 'FETCH_ROOMS_SUCCESS', payload: updatedRooms });
        };

        const handleSocketError = (err) => {
            console.error('Socket error:', err);
        };

        socket.on('updateRoomList', handleUpdateRoomList);
        socket.on('error', handleSocketError);

        socket.emit('requestRoomList');

        return () => {
            socket.off('updateRoomList', handleUpdateRoomList);
            socket.off('error', handleSocketError);
        };
    }, [dispatch, socket]);

    const handlePasswordChange = (roomId, value) => {
        setPasswords((prev) => ({ ...prev, [roomId]: value }));
    };

    const handleJoin = async (room) => {
        if (room.is_open) {
            try {
                setIsJoining((prev) => ({ ...prev, [room.room_id]: true }));
                await dispatch(joinRoom(room.room_id));

                navigate(`/game-rooms/${room.room_id}`);
            } catch (err) {
                setJoinErrors((prev) => ({
                    ...prev,
                    [room.room_id]: err.message || texts.errors.roomNotFound,
                }));
            } finally {
                setIsJoining((prev) => ({ ...prev, [room.room_id]: false }));
            }
        }
    };

    const handleJoinWithPassword = async (roomId) => {
        const password = passwords[roomId] || '';
        if (!password) {
            setJoinErrors((prev) => ({
                ...prev,
                [roomId]: texts.errors.passwordRequired,
            }));
            return;
        }

        try {
            setIsJoining((prev) => ({ ...prev, [roomId]: true }));
            await dispatch(joinRoom(roomId, password));
            setPasswords((prev) => ({ ...prev, [roomId]: '' }));
            setJoinErrors((prev) => ({ ...prev, [roomId]: '' }));

            navigate(`/game-rooms/${roomId}`);
        } catch (err) {
            setJoinErrors((prev) => ({
                ...prev,
                [roomId]: err.message || texts.errors.incorrectPassword,
            }));
        } finally {
            setIsJoining((prev) => ({ ...prev, [roomId]: false }));
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="room-list">
            <h1 className="room-list__title">{texts.roomList.title}</h1>
            {error && <p className="room-list__error">{error}</p>}
            <ul className="room-list__list">
                {rooms.length === 0 && <p>{texts.roomList.noRooms}</p>}
                {rooms.map((room) => (
                    <li key={room.room_id} className="room-list__item">
                        <div className="room-list__info">
                            <p className="room-list__name">{room.room_name}</p>
                            <p className="room-list__details">
                                {texts.roomDetail.mapSize}: {room.map_size}x{room.map_size},{' '}
                                {texts.roomDetail.movesPerPlayer}: {room.moves_per_player}
                            </p>
                        </div>
                        {!room.is_open && (
                            <div className="room-list__password">
                                <input
                                    type="password"
                                    className="room-list__password-input"
                                    placeholder={texts.roomList.enterPassword}
                                    value={passwords[room.room_id] || ''}
                                    onChange={(e) => handlePasswordChange(room.room_id, e.target.value)}
                                />
                                {joinErrors[room.room_id] && (
                                    <p className="room-list__error">{joinErrors[room.room_id]}</p>
                                )}
                            </div>
                        )}
                        <button
                            className="room-list__join-button index__button"
                            onClick={() =>
                                room.is_open
                                    ? handleJoin(room)
                                    : handleJoinWithPassword(room.room_id)
                            }
                            disabled={isJoining[room.room_id]}
                        >
                            {isJoining[room.room_id]
                                ? texts.roomList.joiningButton
                                : room.is_open
                                    ? texts.roomList.joinButton
                                    : texts.roomList.enterPasswordButton}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="room-list__back-button">
                <button className="index__button" onClick={handleBack}>
                    Назад
                </button>
            </div>
        </div>
    );
};

export default RoomList;