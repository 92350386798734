// src/components/GameSession/GameSessionPage.js

import axios from 'axios'; // убедитесь, что axios импортирован
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SocketContext } from '../../contexts/SocketContext';
import { fetchGameSession, fetchGameMaps, BASE_URL } from '../../actions/gameSessionActions';
import { fetchViewedUsers } from '../../actions/userActions';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopPanel from './TopPanel';
import GameChat from './GameChat';
import GameField from './GameField';
import ActionButtons from './ActionButtons';

import './GameSessionPage.css';

const GameSessionPage = () => {
    const socket = useContext(SocketContext);
    const { game_id } = useParams();
    const dispatch = useDispatch();

    const {
        game,
        loading,
        error,
        players,
        playersLoading,
        playersError,
        loadingMaps,
        maps,
        errorMaps
    } = useSelector((state) => state.gameSession);

    const { user, loading: userLoading, error: userError } = useSelector((state) => state.currentUser);

    const [chatMessages, setChatMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedCell, setSelectedCell] = useState(null);
    const [timeLeft, setTimeLeft] = useState(45);
    const messagesEndRef = useRef(null);

    const userNameMap = useMemo(() => {
        const map = {};
        players.forEach(player => {
            map[player.chat_id] = player.gamename;
        });
        return map;
    }, [players]);
    const playerColorMap = useMemo(() => {
        const map = {};
        players.forEach(player => {
            map[player.chat_id] = player.color;
        });
        return map;
    }, [players]);

    useEffect(() => {
        if (game_id) {
            dispatch(fetchGameSession(game_id));
            dispatch(fetchGameMaps(game_id));
        }
    }, [game_id, dispatch]);

    useEffect(() => {
        if (!socket || !user?.chat_id || !game_id) return;

        const handlePreviousMessages = (messages) => {
            setChatMessages(messages);
        };

        const handleNewMessage = (message) => {
            setChatMessages(prev => [...prev, message]);
        };

        const handleGameUpdated = ({ game: updatedGame, map, players }) => {
            dispatch({
                type: 'SOCKET_GAME_UPDATED',
                payload: {
                    game: updatedGame,
                    maps: map,
                    players: players
                }
            });
        };

        socket.on('previousMessages', handlePreviousMessages);
        socket.on('newGameMessage', handleNewMessage);
        socket.on('gameUpdated', handleGameUpdated);

        socket.emit('joinGame', { gameId: game_id, chatId: user.chat_id });

        return () => {
            socket.off('previousMessages', handlePreviousMessages);
            socket.off('newGameMessage', handleNewMessage);
            socket.off('gameUpdated', handleGameUpdated);
        };
    }, [socket, game_id, user?.chat_id]);

    useEffect(() => {
        if (game && game.player_chat_ids && game.player_chat_ids.length > 0) {
            dispatch(fetchViewedUsers(game.player_chat_ids));
        }
    }, [dispatch, game]);

    // При обновлении game в стейте, пересчитываем timeLeft
    useEffect(() => {
        if (!game?.turn_end_time) return;
        const endTime = new Date(game.turn_end_time).getTime();

        const now = Date.now();
        let diff = Math.floor((endTime - now) / 1000);
        if (diff < 0) diff = 0;
        setTimeLeft(diff);
    }, [game?.turn_end_time]);
    // Каждую секунду уменьшаем timeLeft
    useEffect(() => {
        if (timeLeft <= 0) return; // всё, время вышло

        const timerId = setInterval(() => {
            setTimeLeft(prev => {
                if (prev <= 1) {
                    clearInterval(timerId);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft]);

    const handleSendMessage = () => {
        if (!socket) return;
        const trimmedMessage = newMessage.trim();
        if (trimmedMessage === '') return;

        const messageData = {
            gameId: game_id,
            chatId: user.chat_id,
            message: trimmedMessage,
        };

        socket.emit('sendGameMessage', messageData, (ack) => {
            if (ack?.error) {
                console.error('Error sending message:', ack.error);
            }
        });

        setNewMessage('');
    };

    // Колбэк "Ход"
    const handleEndTurn = async () => {
        try {
            const url = `${BASE_URL}/games/${game_id}/makeMove`;
            await axios.post(url);

            setSelectedCell(null);
        } catch (err) {
            console.error('Ошибка при завершении хода', err);
        }
    };

    if ((loading || userLoading || playersLoading || loadingMaps) && (!game || !maps )) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <p className="game-session__error">{error}</p>;
    }
    if (userError) {
        return <p className="game-session__error">{userError}</p>;
    }
    if (playersError) {
        return <p className="game-session__error">{playersError}</p>;
    }
    if (errorMaps) {
        return <p className="game-session__error">{errorMaps}</p>;
    }
    if (!game) {
        return <p className="game-session__error">Игра не найдена.</p>;
    }

    const isUserInGame = game.player_chat_ids.includes(user?.chat_id);
    if (!isUserInGame) {
        return <p className="game-session__error">Вы не участник этой игровой сессии.</p>;
    }

    return (
        <div className="game-session">
            <TopPanel
                resources={{ wood: user.wood, food: user.food }}
                color={user.color}
                currentTurn={game.current_turn}
                totalTurns={game.total_turns}
                activeUser={game.active_user}
                players={players}
                timeLeft={timeLeft}
            />

            <GameField
                cells={maps}
                players={players}
                playerColorMap={playerColorMap}
                selectedCell={selectedCell}
                onSelectCell={setSelectedCell}
            />

            <ActionButtons
                selectedCell={selectedCell}
                userNameMap={userNameMap}
                user={user}
                game={game}
                onEndTurn={handleEndTurn}
            />

            <GameChat
                messages={chatMessages}
                newMessage={newMessage}
                onSendMessage={handleSendMessage}
                onChangeMessage={setNewMessage}
                messagesEndRef={messagesEndRef}
                playerColorMap={playerColorMap}
            />
        </div>
    );
};

export default GameSessionPage;